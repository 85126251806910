import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";
// import Countdown from "react-countdown";

const Header = ({ siteTitle, id, language, setLanguage }) => (
  <header className="has-no-callout">
    {/*<Link className="header-callout" as="div" to="/vote">
      <strong>{language === 'es' ? "Vote Noviembre 2!" : "Vote November 2!"}</strong>
      <span>{language === 'es' ? "Aquí están todos los detalles" : "Here are the details"}</span>
      <i className="icon icon-chevron-right" />
    </Link>*/}
    {/*<a className="header-callout" href="https://bit.ly/3cDm78W" target="_blank" rel="noopener noreferrer">
      {language === 'es' ? "¡Solo queda" : "Only"} <span className="countdown"><Countdown date={'2020-07-11T23:59:00-04:00'} /></span> {language === 'es' ? "contribuya ahora!" : "left, contribute now!"}
    </a>*/}
    <div className="main-logo">
      <Link to="/">
        <img
          src={require(id === "home"
            ? "../images/pi-logo.png"
            : "../images/pi-logo-blue.png")}
          alt="Pierina Sanchez"
        />
      </Link>
    </div>
    <nav className="main-nav">
      {/*
        <Link className="main-nav-link vote" to={'/vote'}>{language === 'es' ? "votar" : "vote"}</Link>
      */}
      {/* <Link className="main-nav-link about" to={"/about"}>
        {language === "es" ? "acerca de" : "about"}
      </Link>
      <Link className="main-nav-link volunteer" to="/volunteer">
        {language === "es" ? "sea voluntario" : "volunteer"}
      </Link>
      <Link className="main-nav-link impact" to={"/impact"}>
        {language === "es" ? "impacto" : "impact"}
      </Link> */}
      {/*<Link className="main-nav-link endorsements" to={'/endorsements'}>{language === 'es' ? "endosos" : "endorsements"}</Link>*/}
      {/* <Link className="main-nav-link jobs" to={"/jobs"}>
        {language === "es" ? "trabajos" : "jobs"}
      </Link> */}
      {/*<Link className="main-nav-link covid" to={'/covid'}>{language === 'es' ? "COVID" : "COVID"}</Link>*/}
      {/*
      <a className="main-nav-link contribute" href={'https://bit.ly/3cDm78W'} target="_blank" rel="noopener noreferrer">{language === 'es' ? "contribuir" : "contribute"}</a>
      <Link className="main-nav-link" to={'/'}>press</Link>
      <Link className="main-nav-link" to={'/'}>events</Link>
      <Link className="main-nav-link" to={'/'}>endorsements</Link>
      */}
      <Link className="main-nav-link contact" to={"/contact"}>
        {language === "es" ? "contacto" : "contact"}
      </Link>
      <button
        className={`btn ${
          id === "home" ? "btn-white" : "btn-primary"
        } btn-hollow`}
        onClick={(event) => {
          event.preventDefault();
          setLanguage(language === "en" ? "es" : "en");
        }}
        title={language === "en" ? "español" : "english"}
      >
        {language === "en" ? "es" : "en"}
      </button>
      <a
        href="https://contribute.nycvotes.org/campaigns/pierinasanchez2/contributions/new"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-quaternary btn-point"
      >
        {language === "es" ? "contribuir" : "contribute"}
      </a>
      {/*
        <Link to="/volunteer" className="btn btn-quaternary btn-point">{language === 'es' ? "sea voluntario" : "volunteer"}</Link>
      */}
    </nav>
  </header>
);

const mapStateToProps = ({ language }) => {
  return { language };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch({ type: `SET_LANGUAGE`, language }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
