import { Link } from "gatsby";
import { connect } from "react-redux";
import React from "react";

const Footer = ({ language }) => (
  <footer>
    <nav className="footer-nav">
      <div className="footer-social">
        <a
          href="https://twitter.com/PiSanchezNYC"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon icon-twitter" aria-label="twitter" />
        </a>
        <a
          href="https://www.facebook.com/PierinaSanchezNYC"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon icon-facebook" aria-label="facebook" />
        </a>
        <a
          href="https://www.instagram.com/PierinaSanchezNYC"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="icon icon-instagram" aria-label="instagram" />
        </a>
        {/*<a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><i className="icon icon-youtube" /></a>*/}
      </div>
      <div className="footer-links" style={{ display: "none" }}>
        {/*<Link to={'/vote'}>{language === 'es' ? "votar" : "vote"}</Link>*/}
        <Link className="footer-link home" to={"/"}>
          {language === "es" ? "inicio" : "home"}
        </Link>
        <Link className="footer-link about" to={"/about"}>
          {language === "es" ? "acerca de" : "about"}
        </Link>
        <Link className="footer-link volunteer" to="/volunteer">
          {language === "es" ? "sea voluntario" : "volunteer"}
        </Link>
        <Link className="footer-link impact" to={"/impact"}>
          {language === "es" ? "impacto" : "impact"}
        </Link>
        <Link className="footer-link jobs" to={"/jobs"}>
          {language === "es" ? "trabajos" : "jobs"}
        </Link>
        {/*<Link className="footer-link issues" to={'/issues'}>{language === 'es' ? "temas" : "issues"}</Link>
        <Link className="footer-link endorsements" to={'/endorsements'}>{language === 'es' ? "endosos" : "endorsements"}</Link>
        
        <Link className="footer-link covid" to={'/covid'}>{language === 'es' ? "COVID" : "COVID"}</Link>
        <Link className="footer-link media" to={'/media'}>{language === 'es' ? "medios de comunicación" : "media"}</Link>*/}
        <Link className="footer-link contact" to={"/contact"}>
          {language === "es" ? "contacto" : "contact"}
        </Link>
        <a
          href="https://contribute.nycvotes.org/campaigns/pierinasanchez2/contributions/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          {language === "es" ? "contribuir" : "contribute"}
        </a>
        {/*<Link to={'/petitions'}>{language === 'es' ? "peticiones" : "petitions"}</Link>
        <Link to={'/'}>events</Link>
        <Link to={'/'}>endorsements</Link>*/}
        {/*<a href="https://bit.ly/3cDm78W" target="_blank" rel="noopener noreferrer" className="btn btn-quaternary btn-point">{language === 'es' ? "contribuir" : "contribute"}</a>*/}
        {/*
          <Link to="/volunteer" className="btn btn-quaternary btn-point">{language === 'es' ? "voluntario" : "volunteer"}</Link>
        */}
      </div>
    </nav>
    {/*<p className="footer-contact">2497 Jerome Ave, Bronx, NY 10468 &nbsp;&ndash;&nbsp;(917) 727-4190</p>*/}
    {/* <div className="footer-paid">
      <p>Paid for by Sanchez for Council</p>
    </div>*/}
    {/*<div className="footer-attribution">
      <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    </div>*/}
  </footer>
);

const mapStateToProps = ({ language }) => {
  return { language };
};

export default connect(mapStateToProps)(Footer);
