import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./Footer";
import "../styles/layout.scss";
import { connect } from "react-redux";

const Layout = ({ children, id, language, setLanguage }) => {
  useEffect(() => {
    if (language === "") {
      const browserLang = navigator.language;
      if (browserLang.indexOf("es") !== -1) {
        setLanguage("es");
      } else {
        setLanguage("en");
      }
    }
  }, [language, setLanguage]);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div id="root" className={`lang-${language}`}>
      <Header siteTitle={data.site.siteMetadata.title} id={id} />
      <div className={`main language-${language}`}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ language }) => {
  return { language };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch({ type: "SET_LANGUAGE", language }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
